/* eslint-disable import/no-anonymous-default-export */
export default {
  commands: {
    echo: {
      description: "Prints the given text to the console",
      usage: "echo <text>",
      fn: (...args) => args.join(" "),
    },
    // cat: {
    //     description: 'Get a cute cat image.',
    //     usage: 'cat',
    //     fn: async () => {
    //         const url = await getcat()
    //         window.open(url, '_blank')
    //         return "fetching cat...\ncat fetched successfully!"
    //     }
    // },
    about: {
      description: "About Me.",
      usage: "about",
      fn: () => {
        return `
                    My info.\n---\n
                    Name            - Rosun GV
                    Email           - gvrosun@gmail.com
                    Phone           - +91 8526220716
                    Location        - Erode, Tamil Nadu, India\n---\n
                `;
      },
    },
    twitter: {
      description: "Opens my Twitter Profile.",
      usage: "twitter",
      fn: () => {
        return "I don't have twitter account! 😅";
      },
    },
    linkedin: {
      description: "Opens my Linkedin Profile.",
      usage: "twitter",
      fn: () => {
        window.open("https://www.linkedin.com/in/gvrosun/", "_blank");
        return "opening my linkedin profile...";
      },
    },
    github: {
      description: "Opens my GitHub Profile.",
      usage: "twitter",
      fn: () => {
        window.open("https://github.com/gvrosun", "_blank");
        return "opening my github profile...";
      },
    },
    discord: {
      description: "Opens my Discord Account.",
      usage: "discord",
      fn: () => {
        return "My discord username: GvR#7792";
      },
    },
    education: {
      description: "My Education details.",
      usage: "education",
      fn: () => {
        return `
                    These are the education history of mine.\n---\n
                    'MTech Integrated Software Engineering'      | 'Vellore Institute of Technology'    | CGPA: 8.54        | 05/2017 - Present
                    'HSC'                                        | 'Bharathi Vidhya Bhavan School'      | Percent: 86.17%   | 05/2016 - 05/2017
                    'SSLC'                                       | 'Bharani Park School'                | Percent: 95.60%   | 05/2014 - 05/2015 \n---\n
                `;
      },
    },
    languages: {
      description: "Languages I know.",
      usage: "languages",
      fn: () => {
        return `
                    These are the languages I know.\n---\n
                    Tamil            - 100%
                    English          - 85%
                    Telugu           - 50%\n---\n
                `;
      },
    },
    skills: {
      description: "Skills I have.",
      usage: "skills",
      fn: () => {
        return `
                    These are the skills I have.\n---\n
                    Python           - 90%
                    Java             - 80%
                    Golang           - 80%
                    SQL              - 70%
                    React            - 70%
                    Docker           - 60%
                    Linux CLI        - 100%\n---\n
                `;
      },
    },
    projects: {
      description: "Projects I have worked on.",
      usage: "projects",
      fn: () => {
        return `
                    Cool projects I have worked on.\n---\n
                    'XSS Vuln Detection and Prevention'      | 'Hackup Technologies (Intern)'    | 'Python, Shell, Hacking Tools'
                    'BotAssist with Smart Alarm using ML'    | 'Personal Project'                | 'Kotlin, Java, ML'
                    'Digital Certificate'                    | 'Final Year Project'              | 'Flask, Python, SQLite'\n---\n
                `;
      },
    },
  },
  overwrites: {
    help: {
      description: "List all available commands",
      usage: "help",
    },
    cd: {
      description: "Change directory, not really, lol!",
      usage: "cd <directory>",
    },
    ls: {
      description: "List files in the current directory",
      usage: "ls",
    },
    mkdir: {
      description: "Make a directory",
      usage: "mkdir <directory>",
    },
    clear: {
      description: "Clears the terminal",
      usage: "clear",
    },
    cat: {
      description: "Display content of any file",
      usage: "cat <filename>",
    },
    exit: {
      description: "Exits current tab",
      usage: "exit",
    },
  },
};
